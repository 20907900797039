$blue: #1E38B6;
$trans: all .25s ease;
$font-gil-r: 'Gilroy-Regular', sans-serif;
$font-gil-m: 'Gilroy-Medium', sans-serif;
$font-gil-b: 'Gilroy-Bold', sans-serif;
$font-gil-blk: 'Gilroy-Black', sans-serif;

$trans: all .25s ease;

$red: #DB2126;

@media (max-width: 1850px) {

}

@media (max-width: 1700px) {

  .col_teacher {

    &:first-of-type {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
      margin-bottom: 104px;
    }

    &:nth-of-type(3),
    &:nth-of-type(4),
    &:nth-of-type(5) {
      flex-basis: 33.3333333333%;
      max-width: 33.3333333333%;
      width: 33.3333333333%;
    }
  }


  .teachers {
    padding-top: 84px;
    padding-bottom: 660px;
  }

  .content {
    max-width: 1300px;
  }

  .price_wrap {

    img {
      max-width: 330px;
    }
  }
}

@media (max-width: 1600px) {
  iframe {
    height: 500px !important;
  }

  .content {
    max-width: 992px;
  }

  .main_screen {
    padding-left: 50px;
    padding-right: 50px;
  }

  .main_screen_contacts {
    left: 50px;
  }

  .top_teacher {
    height: 153px;
  }

  .col_price {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }

  .price_wrap {
    margin-left: auto;
    margin-right: auto;
  }

  .price h2 {
    text-align: center;
  }

  .col_step {

    &:first-of-type {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }

    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4) {
      width: 33.3333333333%;
      max-width: 33.3333333333%;
      flex-basis: 33.3333333333%;
    }
  }

  .steps {
    padding-top: 84px;
  }

  .col_contact_left {
    max-width: 100%;
    width: 100%;
    flex-basis: 100%;
  }

  .col_map {
    max-width: 100%;
    width: 100%;
    flex-basis: 100%;
    height: 500px;
  }

  footer {
   text-align: center !important;
  }

  footer .col_left {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }

  footer .col_right {
    width: 100%;
    max-width: 100%;
    margin-top: 20px;
    flex-basis: 100%;
    text-align: center;
  }

  .steps h2 {
    margin-bottom: 62px;
  }
}

@media (max-width: 1390px) {

  .contacts .animate_figure {
    display: none;
  }

  .main_screen_form {
    position: relative;
    margin-left: 0;
    right: auto;
    top: 0;
    margin-top: 40px;
  }

  .price {
    padding-top: 78px;
  }

  .left_panel {
    padding-left: 30px;
    padding-right: 30px;
  }

  .popap2 h2 {
    font-size: 40px;
    line-height: 53px;
  }

  .popap2 form input[type=text] {
    font-size: 20px;
  }

  .popap2 form input[type=tel] {
    font-size: 20px;
  }

  .popap2 {
    max-width: 650px;
    background: #fff url("../img/popap_bg2.png") no-repeat right -267px bottom;
  }

  .popap1 {
    max-width: 650px;
    background: #fff url("../img/popap_bg1.png") no-repeat right -267px bottom;
  }
}

// Большие девайсы (большие десктопы, < 1200px)
@media (max-width: 1199.98px) {

  .main_left_col {
    max-width: 80px;
    z-index: 30;
  }

  .left_panel {
    position: fixed;
    left: -999px;
    z-index: 20;
    background-color: #1B2C90;
    transition: $trans;

    &.show_menu {
      left: 80px;
    }
  }

  .main_right_col {
    width: calc(100% - 80px);
    max-width: calc(100% - 80px);
    flex-basis: calc(100% - 80px);
  }

  .animate_figure:nth-of-type(2) {
    display: none;
  }

  .main_screen {
    background: url("../img/main_bg_1920.jpg") no-repeat top left;
    background-size: cover;
  }

  .main_screen_contacts {
    display: flex;
    flex-wrap: wrap;

    a:first-of-type {
      margin-right: 30px;
    }

    p {
      width: 100%;
    }
  }

  .map {
    height: 500px;
  }

  .left_panel_mobile {
    position: sticky;
    top: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .mobile_logo {
      position: absolute;
      top: 30px;

      img {
        max-width: 58px;
      }
    }
  }

  .hamburger {
    height: 22px;
    margin-right: 54px;
    padding: 0;
    outline: none !important;
  }

  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    height: 2px;
    background-color: #fff;
  }

  .mobile_hamburger {
    width: 40px;
    text-align: center;

    p {
      margin-top: 8px;
      margin-left: -3px;
      font-size: 16px;
      color: #fff;
    }
  }

  .popap2,
  .popap1 {
    max-width: 320px;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .popap2 h2,
  .popap1 h2 {
    margin-bottom: 17px;
    font-size: 24px;
    line-height: 32px;
  }

  .popap1 form input[type=text],
  .popap2 form input[type=text] {
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
  }

  .popap1 form input[type=tel],
  .popap2 form input[type=tel] {
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
  }

  .popap1 form .inputs_wrap:before,
  .popap2 form .inputs_wrap:before {
    top: 60px;
  }

  .popap2 form textarea {
    height: 100px;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .popap1 form .inputs_wrap:after,
  .popap2 form .inputs_wrap:after {
    width: 90%;
    left: 50%;
    top: 120px;
    margin-left: -45%;
  }

  .popap1 form .orange_btn,
  .popap2 form .orange_btn {
    height: 60px;
    font-size: 16px;
  }

  .popap1 form .inputs_wrap,
  .popap2 form .inputs_wrap {
    margin-bottom: 14px;
  }

  .popap1 .chekbox_wrap,
  .popap2 .chekbox_wrap {
    margin-bottom: 27px;
  }

  .popap1 form .inputs_wrap:before,
  .popap2 form .inputs_wrap:before {
    max-width: 90%;
    left: 50%;
    margin-left: -45%;
  }

  .mfp-close-btn-in .mfp-close {
    font-size: 40px;
    top: -40px;
    right: -60px;
  }

  .success_form1 .success_wrap {
    max-width: 648px;
  }

  .success_form2 .success_wrap {
    max-width: 648px;
  }
}

// Средние девайсы («таблетки», < 992px)
@media (max-width: 991.98px) {

  .animate_figure:nth-of-type(1) {
    display: none;
  }

  .main_screen form {
    max-width: 574px;
    flex-wrap: wrap;
  }

  .main_screen form .red_btn {
    max-width: 100%;
    margin-top: 5px;
    margin-left: 0;
  }

  .main_screen_contacts {
    top: 84px;
  }

  .advantage {
    margin-left: auto;
    margin-right: auto;
  }

  .advantages {
    padding-top: 70px;
  }

  .main_screen {
    padding-bottom: 86px;
  }

  .col_teacher:nth-of-type(2), .col_teacher:nth-of-type(3), .col_teacher:nth-of-type(4) {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }

  .teachers {
    padding-bottom: 40px;
  }

  .teacher_wrap {
    margin-bottom: 100px;
    position: relative;
  }

  .bottom_teacher {
    margin-left: auto;
    margin-right: auto;
    max-width: 316px;
  }

  .col_teacher h2 {
    text-align: center;
  }

  .col_teacher h4 {
    text-align: center;
  }

  .steps .form_wrap form {
    max-width: 574px;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
  }

  .steps .form_wrap p {
    text-align: center;
    color: #fff;
  }

  .steps .form_wrap form .red_btn {
    max-width: 100%;
    margin-top: 5px;
    margin-left: 0;
  }

  .col_step:nth-of-type(2), .col_step:nth-of-type(3), .col_step:nth-of-type(4) {
    max-width: 100%;
    width: 100%;
    flex-basis: 100%;
  }

  .step_item {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .steps h2 {
    text-align: center;
  }

  .success_form1 .success_wrap {
    padding-top: 30px;
    padding-bottom: 30px;
    max-width: 400px;

  }

  .success_form1 h2 {
    margin-left: 15px;
    margin-right: 15px;
    font-size: 24px;
    line-height: 32px;
  }

  .success_form1 p {
    margin-left: 15px;
    margin-right: 15px;
    font-size: 16px;
    line-height: 21px;
  }

  .success_form2 .success_wrap {
    padding-top: 30px;
    padding-bottom: 30px;
    max-width: 400px;

  }

  .success_form2 h2 {
    margin-left: 15px;
    margin-right: 15px;
    font-size: 24px;
    line-height: 32px;
  }

  .success_form2 p {
    margin-left: 15px;
    margin-right: 15px;
    font-size: 16px;
    line-height: 21px;
  }

  .row_teacher>.col_teacher:nth-of-type(2),
  .row_teacher>.col_teacher:nth-of-type(3),
  .row_teacher>.col_teacher:nth-of-type(4),
  .row_teacher>.col_teacher:nth-of-type(5){
    display: none;
  }

  .slider_teacher_tablet {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    position: relative;

    .col_teacher:nth-of-type(1), .col_teacher:nth-of-type(2), .col_teacher:nth-of-type(3) {
      max-width: 330px;
      width: 330px;
      flex-basis: 330px;
    }

    .teacher_wrap {
      padding-top: 64px;
    }

    .bottom_teacher {
      margin-top: -10px;
    }

    .teacher_wrap {
      margin-bottom: 0;
    }
  }

  .col_teacher:first-of-type {
    margin-bottom: 40px;
  }

  .slider_ico {
    display: block;
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -25px;
    text-align: center;
    z-index: 40;
  }

  .teachers {
    padding-bottom: 100px;
  }
}


// Малые девайсы («ландшафтные», < 768px)
@media (max-width: 767.98px) {

  .left_panel_mobile {
    width: 100%;
    height: 70px;
    flex-direction: row;
  }

  .main_left_col {
    max-width: 100%;
    position: relative;
  }

  .left_panel_mobile .mobile_logo {
    position: relative;
    top: auto;
  }

  .main_right_col {
    max-width: 100%;
    width: 100%;
    flex-basis: 100%;
  }

  .mobile_hamburger p {
    display: none;
  }

  .hamburger {
    margin-right: 0;
    position: absolute;
    left: 30px;
    top: 24px;
  }

  .mobile_logo {
    display: flex;
    align-items: center;
    text-decoration: none !important;

    span {
      display: block;

      &:first-of-type {
        padding-left: 11px;
        font-family: $font-gil-b;
        font-size: 17px;
        line-height: 15px;
        color: #fff;
      }

      &:last-of-type {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 11px;
        width: 11px;
        bottom: 4px;
        right: -6px;
        font-weight: $font-gil-b;
        color: #fff;
        font-size: 7px;
        background-color: #F73D46;
        border-radius: 50%;
      }
    }
  }

  .left_panel.show_menu {
    top: 70px;
    padding-top: 16px;
    padding-bottom: 40px;
    left: 0;
    right: 0;
  }

  .left_panel {
    left: 0;
    right: 0;
    top: -999px;
    position: absolute;
    height: auto;

    &.show_menu {
      top: 70px;
    }
  }

  .left_panel .col_top {
    display: none;
  }

  .bottom_social_wrap {
    position: relative;
    bottom: auto;
  }

  .left_panel ul li {
    text-align: center;
    margin-bottom: 28px;

    a {
      font-size: 16px;
    }
  }

  .bottom_social_wrap {
    margin-left: auto;
    margin-right: auto;
  }

  .social_wrap {
    display: flex;
    justify-content: center;
  }

  .bottom_social_wrap .grey_border_btn {
    margin-bottom: 26px;
  }

  .mobile_phones {
    margin-bottom: 26px;
    text-align: center;

    a {
      font-family: $font-gil-b;
      font-size: 20px;
      line-height: 27px;
      text-decoration: none !important;
      color: #fff;
      transition: $trans;

      &:hover {
        color: $red;
      }
    }

    p {
      color: rgba(255,255,255,.7);
    }
  }

  .left_panel nav {
    margin-bottom: 30px;
  }

  .left_panel_mobile {
    z-index: 10000;
  }

  .main_screen_contacts {
    display: none;
  }

  .main_screen {
    padding-top: 43px;
    padding-bottom: 40px;
    background: url("../img/main_bg_768.jpg") no-repeat center;
    background-size: cover;
  }

  .main_screen h1 {
    font-size: 24px;
    line-height: 32px;
  }

  .main_screen h3 {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 24px;
  }

  .promotion {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;
  }

  .promotion .date {
    margin-left: 0;
  }

  .main_screen .form_wrap p {
    font-size: 14px;
    margin-bottom: 17px;
  }

  .main_screen form input[type=text] {
    height: 60px;
    max-width: 100%;
    width: 100%;
    font-size: 16px;
    border-radius: 4px 4px 0 0;
  }

  .main_screen form input[type=tel] {
    height: 60px;
    max-width: 100%;
    width: 100%;
    font-size: 16px;
    border-radius: 0 0 4px 4px;
  }

  .main_screen form {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      display: block;
      height: 1px;
      width: 80%;
      top: 60px;
      left: 50%;
      margin-left: -40%;
      background-color: #F5F5F5;
      z-index: 10;
    }
  }

  .main_screen form .red_btn {
    height: 60px;
    font-size: 16px;
  }

  .advantages {
    padding-top: 40px;
    padding-bottom: 20px;
  }

  .teachers {
    padding-top: 31px;
    background: url("../img/teacher_bg.jpg") no-repeat left center;
    background-size: cover;
  }

  .col_advantage p {
    font-size: 16px;
    line-height: 21px;
  }

  .col_teacher h2 {
    margin-bottom: 8px;
    font-size: 24px;
  }

  .col_teacher h4 {
    font-size: 18px;
    line-height: 24px;
  }

  .bottom_teacher h3 {
    font-size: 20px;
    line-height: 27px;
  }

  .bottom_teacher h5 {
    font-size: 14px;
    line-height: 19px;
  }

  .bottom_teacher p {
    font-size: 14px;
    line-height: 19px;
  }

  .show_teacher_more {
    font-size: 14px;
  }

  .bottom_teacher {
    padding-top: 35px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .steps h2 {
    font-size: 24px;
    line-height: 32px;
  }

  .col_step .step_item:before {
    font-size: 20px !important;
  }

  .step_item p,
  .step_item a.red_anchor {
    font-size: 16px;
    line-height: 21px;
  }

  .step_item {
    margin-bottom: 60px;
    padding-top: 130px;
    padding-bottom: 34px;
  }

  .step_item p:before {
    top: -39px;
  }

  .steps form input[type=text] {
    height: 60px;
    max-width: 100%;
    width: 100%;
    font-size: 16px;
    border-radius: 4px 4px 0 0;
  }

  .steps form input[type=tel] {
    height: 60px;
    max-width: 100%;
    width: 100%;
    font-size: 16px;
    border-radius: 0 0 4px 4px;
  }

  .steps .form_wrap form {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      display: block;
      height: 1px;
      width: 80%;
      top: 60px;
      left: 50%;
      margin-left: -40%;
      background-color: #F5F5F5;
      z-index: 10;
    }
  }

  .steps .form_wrap form .red_btn {
    height: 60px;
    font-size: 16px;
  }

  .steps .form_wrap p {
    margin-bottom: 15px;
    font-size: 14px;
    color: rgba(255,255,255,.7);
  }

  .steps .form_wrap {
    margin-top: 5px;
    padding-bottom: 54px;
  }

  .contact_wrap address {
    font-size: 16px;
  }

  .contact_phones a {
    font-size: 20px;
    line-height: 20px;
  }

  .contact_wrap .work_time {
    font-size: 14px;
    margin-bottom: 14px;
  }

  .contact_mail a {
    font-size: 16px;
  }

  .contact_wrap .orange_btn {
    height: 50px;
    max-width: 220px;
    font-size: 16px;
  }

  .contact_wrap {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .contact_mail {
    margin-bottom: 28px;
  }

  footer .col_left {
    font-size: 14px;
    line-height: 19px;
  }

  footer .col_right a {
    font-size: 14px;
    line-height: 19px;
  }

  footer {
    padding-bottom: 30px;
    padding-top: 30px;
  }
}

@media (max-width: 650px) {
  .price h2 {
    margin-bottom: 25px;
    font-size: 24px;
  }

  .price_wrap h3 {
    max-width: 220px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 28px;
    font-size: 20px;
    line-height: 27px;

    br {
      display: none;
    }
  }

  .price_curse p {
    max-width: 220px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4px;
    font-size: 30px;
  }

  .price_curse {
    max-width: 220px;
    margin-left: auto;
    margin-right: auto;
  }

  .price_curse span {
    font-size: 14px;
  }

  .price_wrap a.blue_btn,
  .price_wrap a.green_btn,
  .price_wrap a.orange_btn,
  .price_wrap a.red_btn {
    max-width: 220px;
    margin-left: auto;
    margin-right: auto;
    height: 50px;
    font-size: 16px;
  }

  .price_wrap {
    padding-left: 0;
    padding-right: 0;
    padding-top: 31px;
    padding-bottom: 230px;
  }

  .price {
    padding-top: 40px;
    padding-bottom: 20px;
  }

  .price_wrap img:nth-of-type(1) {
    display: none;
  }

  .price_wrap img:nth-of-type(2) {
    display: block;
  }

  .price_wrap {
    padding-bottom: 250px;
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;
  }
}

// Экстрамалые девайсы («телефоны», < 576px)
@media (max-width: 575.98px) {

  .main_screen {
    padding-left: 15px;
    padding-right: 15px;
  }

  .main_screen form {
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;
  }

  .main_screen .form_wrap p {
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;
  }

  .price_wrap {
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;
  }

  .steps .form_wrap form {
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;
  }

  .contact_left_wrap {
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;
  }

  .map {
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;
  }

  .slider_teacher_tablet,
  .slider_ico {
    display: none;
  }

  .row_teacher > .col_teacher:nth-of-type(2),
  .row_teacher > .col_teacher:nth-of-type(3),
  .row_teacher > .col_teacher:nth-of-type(4),
  .row_teacher > .col_teacher:nth-of-type(5) {
    display: block;
  }

  .col_teacher:nth-of-type(5) {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }

  .col_teacher h4 {
    margin-bottom: 60px;
  }

  .teachers {
    padding-bottom: 20px;
  }
}